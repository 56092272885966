import React from "react";
import { Home, Data, NoMatch } from "./Routes";
import Alert from "./Alert";
import { Meta, Header } from "./Header";
import Footer from "./Footer";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import "./App.scss";

function App() {
  return (
    <Router>
      <div className="app">
        {/* Default meta */}
        <Meta
          title="How Good is Australia"
          description="Assessing our wellbeing and progress, referencing open data, global rankings, research and journalism."
        />
        <Alert />
        <Header />
        <main className="main">
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route path="/data-and-statistics">
              <Data />
            </Route>
            <Route path="*">
              <NoMatch />
            </Route>
          </Switch>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
