import React from "react";
import "./Alert.scss";

function Alert(props) {
  const { label } = props;
  return (
    <div className="section-alert">
      <span>{label}</span>
    </div>
  );
}

export default Alert;
