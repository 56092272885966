import React from "react";
import "./Footer.scss";

const Footer = () => {
  return (
    <div className="footer">
      <div className="container">
        <div className="grid footer-content">
          <div className="col acknowledgement">
            <span>
              We respectfully acknowledge the Traditional Custodians of Brisbane
              and surrounding areas.
            </span>
          </div>
          <div className="col built-by">Hand crafted in Brisbane.</div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
