import React from "react";
import "./Text.scss";

function Text(props) {
  const { title, children } = props;
  return (
    <div className="block-text">
      <div className="container">
        <div className="grid border">
          <div className="col text-title">{title}</div>
          <div className="col text-content">{children}</div>
        </div>
      </div>
    </div>
  );
}

export default Text;
