import React from "react";
import { Cover, Intro } from "../Blocks";

function NoMatch() {
  return (
    <>
      <Cover title="404" />
      <div className="container">
        <div className="grid">
          <div className="col">
            <Intro caption="Hmm. We can’t find the page you’re looking for. Sorry for the inconvenience." />
          </div>
        </div>
      </div>
    </>
  );
}

export default NoMatch;
