import React from "react";
import Intro from "./Intro";
import "./Cover.scss";

function Cover({ title, caption, image }) {
  return (
    <section className="cover">
      <div className="container">
        <div className="grid cover-content">
          <div className="col">
            <h1 className="name">{title}</h1>
            <Intro caption={caption} />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Cover;
