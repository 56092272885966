import React from "react";
import { Cover, Text } from "../Blocks";
import { Meta } from "../Header";

function Home() {
  return (
    <>
      <Meta
        title="Data | How Good is Australia"
        description="Data references that help us measure how good is Australia."
      />
      <Cover title="Data" caption="" image="" />
      <Text title="Coming Soon" />
    </>
  );
}

export default Home;
