import React from "react";
import { Cover, Text } from "../Blocks";

function Home() {
  return (
    <>
      <Cover
        title="How Good Is Australia?"
        caption="Assessing our wellbeing and progress, referencing open data, global rankings, research and journalism."
        image="outback"
      />
      <Text title="Broadband Internet">
        <p className="block-lead">
          National fixed broadband speeds in Australia are among the lowest in
          the developed world.
        </p>
        <p>
          According to the{" "}
          <a
            href="https://www.cable.co.uk/broadband/speed/worldwide-speed-league/"
            rel="noopener noreferrer"
            target="_blank"
          >
            Speed Test Global Index
          </a>
          , Australia is ranked 62<sup>nd</sup> globally for Fixed Broadband at
          58.83Mbps compared with 226.60 Mbps for the leading country,
          Singapore.
        </p>
        <p>
          Quality broadband services facilitate commerce and trade and remove
          obstacles and costs for small businesses. It is no coincidence that
          global hubs of investment and technology also maintain world class
          broadband infrastructure.
        </p>
      </Text>
    </>
  );
}

export default Home;
