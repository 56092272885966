import React from "react";
import { Link } from "react-router-dom";
import "./Header.scss";

const Header = () => {
  return (
    <header className="header">
      <div className="container">
        <div className="grid header-content">
          <div className="col header-logo">&nbsp;</div>
          <div className="col header-nav">
            <ul className="menu">
              <li className="menu-item">
                <Link to="/">Articles</Link>
              </li>
              <li className="menu-item">
                <Link to="/data-and-statistics">Data</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
